<template>
	<div>
		<navComponents />
		<div class="bg1" :style="{ width: screenWidth }"></div>
		<div class="bg2" :style="{ width: screenWidth }" v-if="!isMobile">
			<div class="container_box">
				<div class="title">
					<div class="title-left">「招募计划」</div>
					<div class="title-right">简历投递邮箱：hr@istariver.com</div>
				</div>
				<div class="main">
					<div class="main-left">
						<p class="menu-title">岗位名称</p>
						<el-menu default-active="1" class="el-menu-vertical-demo" @select="selectJob">
							<el-menu-item index="1">
								<span slot="title">渠道销售经理</span>
							</el-menu-item>
							<el-menu-item index="2">
								<span slot="title">资深软件工程师</span>
							</el-menu-item>
							<el-menu-item index="3">
								<span slot="title">自动化测试</span>
							</el-menu-item>
						</el-menu>
					</div>
					<div class="main-right">
						<div class="right-item" v-show="cardIndex === '1'">
							<p class="item-title">渠道销售经理（1人）</p>
							<div class="right-item-card">
								<p class="card-title">岗位描述：</p>
								<div class="card-content">
									<p>
										1、参与公司渠道体系战略的制定与管理，负责具体渠道代理商拓展计划的方案制定、执行与管理，并制定面向不同类型渠道商的合作流程、方案与规则；
									</p>
									<p>
										2、负责可持续发展的渠道管理体系制定及落地，形成从渠道/合作伙伴认证、战略合作协议、线索拓展、客户管理、商机管理、签约以及其他业务合作的管理体系；
									</p>
									<p>
										3、负责渠道/合作伙伴人员的培训及能力认证体系的制定及管理，包括制定培训计划、组织协调售前咨询部、服务部、产品部等技术专家为渠道商/合作伙伴进行素质培训、学员的培训考核以及能力认证；
									</p>
									<p>
										4、负责代理过数据中间件的渠道代理商的深度挖掘工作，并与其关键决策人建立合作关系，拓展合作项目，并赋能渠道商/合作伙伴完成客户交流、业务演示、需求梳理、POC测试、项目答标、商务谈判及合同签订等；
									</p>
								</div>
							</div>
							<div class="right-item-card">
								<p class="card-title">任职要求：</p>
								<div class="card-content">
									<p>1、理工科教育背景，2年及以上企业服务领域渠道/销售工作经验。（中间件、底层技术类产品市场经验优先）；</p>
									<p>2、熟悉企业服务领域技术类产品的渠道/合作伙伴生态。（中间件、数据融合领域经验优先）；</p>
									<p>3、沟通协调能力强，对复杂环境具有承压能力。（有渠道/合作伙伴管理经验优先）；</p>
									<p>4、有进取心，学习能力强，对前沿技术与企业服务领域抱有热情。</p>
								</div>
							</div>
						</div>
						<div class="right-item" v-show="cardIndex === '2'">
							<p class="item-title">资深软件工程师（1人）</p>
							<div class="right-item-card">
								<p class="card-title">岗位描述：</p>
								<div class="card-content">
									<p>1、参与易扩展，高可用，数据同步工具开发。负责核心产品各模块的设计，开发，测试，上线。</p>
									<p>2、制定合理的开发工作计划，并有效地按照计划完成工作。</p>
									<p>3、积极了解业界发展.相关新技术及趋势，促进技术进步和创新。</p>
								</div>
							</div>
							<div class="right-item-card">
								<p class="card-title">任职要求：</p>
								<div class="card-content">
									<p>1、负责实时计算平台的架构设计及关键功能模块的设计和开发。</p>
									<p>2、根据需求进行概要设计及详细设计以及模块的实现。</p>
									<p>3、本科及以上学历，计算机相关专业，5年以上工作经验。</p>
									<p>4、Java基础扎实，精通多线程、并发、集合、网络、io等基础知识，熟悉JVM。</p>
									<p>5、熟练掌握各类算法、常用数据结构、设计模式。</p>
									<p>6、理解SpringMVC，SpringBoot, SpringCloud, Mybatis, Dubbo, zookeeper等领域的框架或产品的机制与代码。</p>
									<p>7、熟悉 SQL、NoSQL、缓存、消息通信、队列、异步框架等技术。</p>
								</div>
							</div>
						</div>
						<div class="right-item" v-show="cardIndex === '3'">
							<p class="item-title">自动化测试（1人）</p>
							<div class="right-item-card">
								<p class="card-title">岗位描述：</p>
								<div class="card-content">
									<p>1、根据需求完成产品的自动化测试、升级测试；</p>
									<p>2、根据需求完成产品的部分功能测试；</p>
									<p>3、负责自动化脚本的开发与维护；</p>
									<p>4、根据需求和交付要求编写测试脚本，搭建自动化测试环境，执行测试及Bug跟踪；</p>
								</div>
							</div>
							<div class="right-item-card">
								<p class="card-title">任职要求：</p>
								<div class="card-content">
									<p>1、计算机或相关专业二本及以上学历；</p>
									<p>2、1年以上软件开发或自动化测试工作经验，有很强的逻辑思维能力，和独立解决问题的能力；</p>
									<p>3、熟练使用Linux，熟悉MySQL、Oralce、SqlServer等关系型数据库的使用，了解Hive、GreenPlum等数仓；</p>
									<p>4、熟练使用Jmeter、Selenium等自动化测试工具；</p>
									<p>5、熟悉python语言；</p>
									<p>6、具有接口自动化测试经验、自动化测试框架平台开发或者大数据相关经验者优先。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile" v-else>
			<div class="bg1-2">招募计划</div>
			<swiper ref="mySwiper" :options="swiperOptions">
				<swiper-slide v-for="(item, index) in bg2Data" :key="index">
					<div class="swiper-item" :class="item.active ? 'active' : ''" @click="chooseCur(item, index)">{{ item.title }}</div>
				</swiper-slide>
				<!-- 如果你需要分页器 -->
				<!-- <div class="swiper-pagination" slot="pagination"></div> -->
			</swiper>
			<div class="main-right">
				<div class="right-item" v-show="activeIndex === 0">
					<p class="item-title">渠道销售经理（1人）</p>
					<div class="right-item-card">
						<p class="card-title">岗位描述：</p>
						<div class="card-content">
							<p>
								1、参与公司渠道体系战略的制定与管理，负责具体渠道代理商拓展计划的方案制定、执行与管理，并制定面向不同类型渠道商的合作流程、方案与规则；
							</p>
							<p>
								2、负责可持续发展的渠道管理体系制定及落地，形成从渠道/合作伙伴认证、战略合作协议、线索拓展、客户管理、商机管理、签约以及其他业务合作的管理体系；
							</p>
							<p>
								3、负责渠道/合作伙伴人员的培训及能力认证体系的制定及管理，包括制定培训计划、组织协调售前咨询部、服务部、产品部等技术专家为渠道商/合作伙伴进行素质培训、学员的培训考核以及能力认证；
							</p>
							<p>
								4、负责代理过数据中间件的渠道代理商的深度挖掘工作，并与其关键决策人建立合作关系，拓展合作项目，并赋能渠道商/合作伙伴完成客户交流、业务演示、需求梳理、POC测试、项目答标、商务谈判及合同签订等；
							</p>
						</div>
					</div>
					<div class="right-item-card">
						<p class="card-title">任职要求：</p>
						<div class="card-content">
							<p>1、理工科教育背景，2年及以上企业服务领域渠道/销售工作经验。（中间件、底层技术类产品市场经验优先）；</p>
							<p>2、熟悉企业服务领域技术类产品的渠道/合作伙伴生态。（中间件、数据融合领域经验优先）；</p>
							<p>3、沟通协调能力强，对复杂环境具有承压能力。（有渠道/合作伙伴管理经验优先）；</p>
							<p>4、有进取心，学习能力强，对前沿技术与企业服务领域抱有热情。</p>
						</div>
					</div>
				</div>
				<div class="right-item" v-show="activeIndex === 1">
					<p class="item-title">资深软件工程师（1人）</p>
					<div class="right-item-card">
						<p class="card-title">岗位描述：</p>
						<div class="card-content">
							<p>1、参与易扩展，高可用，数据同步工具开发。负责核心产品各模块的设计，开发，测试，上线。</p>
							<p>2、制定合理的开发工作计划，并有效地按照计划完成工作。</p>
							<p>3、积极了解业界发展.相关新技术及趋势，促进技术进步和创新。</p>
						</div>
					</div>
					<div class="right-item-card">
						<p class="card-title">任职要求：</p>
						<div class="card-content">
							<p>1、负责实时计算平台的架构设计及关键功能模块的设计和开发。</p>
							<p>2、根据需求进行概要设计及详细设计以及模块的实现。</p>
							<p>3、本科及以上学历，计算机相关专业，5年以上工作经验。</p>
							<p>4、Java基础扎实，精通多线程、并发、集合、网络、io等基础知识，熟悉JVM。</p>
							<p>5、熟练掌握各类算法、常用数据结构、设计模式。</p>
							<p>6、理解SpringMVC，SpringBoot, SpringCloud, Mybatis, Dubbo, zookeeper等领域的框架或产品的机制与代码。</p>
							<p>7、熟悉 SQL、NoSQL、缓存、消息通信、队列、异步框架等技术。</p>
						</div>
					</div>
				</div>
				<div class="right-item" v-show="activeIndex === 2">
					<p class="item-title">自动化测试（1人）</p>
					<div class="right-item-card">
						<p class="card-title">岗位描述：</p>
						<div class="card-content">
							<p>1、根据需求完成产品的自动化测试、升级测试；</p>
							<p>2、根据需求完成产品的部分功能测试；</p>
							<p>3、负责自动化脚本的开发与维护；</p>
							<p>4、根据需求和交付要求编写测试脚本，搭建自动化测试环境，执行测试及Bug跟踪；</p>
						</div>
					</div>
					<div class="right-item-card">
						<p class="card-title">任职要求：</p>
						<div class="card-content">
							<p>1、计算机或相关专业二本及以上学历；</p>
							<p>2、1年以上软件开发或自动化测试工作经验，有很强的逻辑思维能力，和独立解决问题的能力；</p>
							<p>3、熟练使用Linux，熟悉MySQL、Oralce、SqlServer等关系型数据库的使用，了解Hive、GreenPlum等数仓；</p>
							<p>4、熟练使用Jmeter、Selenium等自动化测试工具；</p>
							<p>5、熟悉python语言；</p>
							<p>6、具有接口自动化测试经验、自动化测试框架平台开发或者大数据相关经验者优先。</p>
						</div>
					</div>
				</div>
			</div>
			<div class="mail">简历投递邮箱：hr@istariver.com</div>
		</div>
		<CopyrightComponents />
	</div>
</template>

<script>
//页面引入vue-awesome-swiper 及其样式
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
export default {
	components: { navComponents, CopyrightComponents, swiper, swiperSlide },
	props: {},
	data() {
		return {
			screenWidth: "",
			screenHeight: "",
			cardIndex: "1",
			activeIndex: 0,
			swiperOptions: {
				// 这里配置Swiper的参数
				// 例如：自动播放、分页等
				// autoplay: {
				// 	delay: 2500,
				// 	disableOnInteraction: false,
				// },
				// pagination: {
				// 	el: ".swiper-pagination",
				// 	clickable: true,
				// },
				// 其他配置...
				slidesPerView: "auto",

				freeMode: true,
				on: {
					//当前swiper-slide点击时触发事件
					click: function (e) {
						let thisWidth = this.clickedSlide.offsetWidth;
						let thisLeft = this.clickedSlide.offsetLeft;
						let offsetX = this.width / 2 - thisLeft - thisWidth / 2;
						//偏移量在1/2视口内时，容器不发生偏移
						if (offsetX > 0) {
							offsetX = 0;
						}
						//offsetX小于容器最大偏移时，让偏移量等于容器最大的偏移量
						else if (offsetX < this.maxTranslate()) {
							offsetX = this.maxTranslate();
						}
						//设置容器的过渡动画
						this.setTransition(300);
						this.setTranslate(offsetX);
						//滚动条位置和长度执行动画
						$(".swiper-wrapper span").animate({ left: thisLeft, width: thisWidth }, 400);
					},
				},
			},
			bg2Data: [
				{
					title: "产品经理",
					active: true,
				},
				{
					title: "软件工程师",
					active: false,
				},
				{
					title: "自动化测试",
					active: false,
				},
			],
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {
		screenWidth() {
			console.log(this.screenWidth); //浏览器窗口变化时，打印宽度。
		},
	},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {
		selectJob(index) {
			this.cardIndex = index;
		},
		chooseCur(item, index) {
			this.bg2Data.forEach(v => {
				v.active = false;
			});
			item.active = true;
			this.activeIndex = index;
		},
	},
};
</script>

<style scoped lang="scss">
.bg1 {
	height: 550px;
	background-image: url("~@/assets/images/recruitment-navbg.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	color: #fff;
	margin: 0 auto;
	.w {
		width: 1300px;
		margin: 0 auto;
		overflow: hidden;
		p {
			width: 820px;
			height: 78px;
			font-size: 30px;
			font-family: "DFPLiJinHeiW8-GB";
			font-weight: 600;
			color: #ffffff;
			margin-top: 217px;
			line-height: 42px;
		}
	}
}
.bg2 {
	.container_box {
		.title {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #dbdbdb;
			padding-bottom: 8px;
			margin-bottom: 15px;
			margin-top: 20px;
			.title-left {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #1b1b1b;
				line-height: 25.6px;
			}
			.title-right {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 25.6px;
			}
		}
		.main {
			display: flex;
			justify-content: space-between;
			margin-bottom: 40px;
			height: 700px;
			.main-left {
				width: 200px;
				background: #ffffff;
				border: 1px solid #e5e5e5;
				.menu-title {
					width: 200px;
					background: #f7f7f7;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #000000;
					line-height: 50px;
					text-align: center;
				}
				.el-menu {
					border: 0;
					.el-menu-item {
						height: 40px;
						line-height: 40px;
						border-top: 1px solid #e5e5e5;
						text-align: center;
						&:last-child {
							border-bottom: 1px solid #e5e5e5;
						}
						&:hover {
							background-color: #fff;
						}
						&.is-active {
							background: #dbe4f3;
							color: #000000;
							position: relative;
							&::after {
								content: "";
								display: inline-block;
								width: 0;
								height: 0;
								position: absolute;
								border-left: 8px solid #dbe4f3;
								border-top: 8px solid transparent;
								border-bottom: 8px solid transparent;
								border-right: 8px solid transparent;
								top: 12px;
								right: -16px;
							}
						}
					}
				}
			}
			.main-right {
				width: 1085px;
				background: #ffffff;
				border: 1px solid #e5e5e5;
				.right-item {
					padding: 20px 70px;
					.item-title {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						line-height: 25.6px;
						position: relative;
						&::before {
							content: "";
							display: inline-block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #e5e5e5;
							top: 50%;
							left: -40px;
						}
					}
					.card-title {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						line-height: 25.6px;
						margin-bottom: 20px;
						margin-top: 40px;
					}
					.card-content {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #656565;
						line-height: 25.6px;
					}
				}
			}
		}
	}
}
.mobile {
	background-color: #f7f7f7;
	padding: 0 10px;
	.bg1-2 {
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		&::before {
			content: "";
			display: inline-block;
			width: 60px;
			height: 2px;
			background-color: #000;
			margin-bottom: 5px;
			margin-right: 30px;
		}
		&::after {
			content: "";
			display: inline-block;
			width: 60px;
			height: 2px;
			background-color: #000;
			margin-bottom: 5px;
			margin-left: 30px;
		}
	}
	.swiper-container {
		padding-bottom: 15px;
		border-bottom: 1px solid #e4e4e4;
		margin-bottom: 15px;
	}
	.swiper-slide {
		width: auto !important;
		margin-right: 20px;
		.swiper-item {
			border-radius: 5px 5px 5px 5px;
			background-color: #fff;
			color: #595e67;
			padding: 7px 10px;
			font-size: 14px;
			&.active {
				color: #fff;
				background-color: #297aff;
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.right-item {
		font-family: PingFang SC, PingFang SC;
		color: #000000;
		.item-title {
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
		}
		.right-item-card {
			font-size: 14px;
			line-height: 30px;
			.card-title {
				font-weight: bold;
				margin-top: 15px;
			}
		}
	}
	.mail {
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #000000;
		line-height: 32px;
	}
}

@media (max-width: 900px) {
	.bg1 {
		background-image: url("~@/assets/images/recruitment-navbg-mobile.png");
		height: 340px;
	}
}
</style>
